import React from 'react';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';

export default function Privacy({ data }) {
  // console.log(data);
  const { mdx = {} } = data;
  return (
    <Layout current="childrenprivacy" subCurrent="childrenprivacy" type="privacy">
      <div className="privacy-md-container" style={{ textAlign: 'left', padding: '0 30px 30px' }}>
        <MDXProvider>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query MdxBlogPost {
    mdx(frontmatter: { title: { eq: "儿童隐私保护声明" } }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
